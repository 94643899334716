import Layout from "../../layouts"
import React, { useState, useContext, useEffect } from "react"

import { CfpSearch } from "../../components/cfp/cfpSearch"
import { Box } from "@mui/material"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { CfpheaderCards } from "../../components/cfp/muidb/cfpheadercards"
import Cfptb from "../../components/cfp/cfptb"
import { cfpColumns } from "../../components/utils/cfpcolumns"
import Hider from "../../components/utils/Hider"
import { gUtils } from "../../components/utils/gutils"
import {navigate} from "gatsby";
import {gConfig} from "../../chatai/utils/config";

export default function CmFace({ location }) {
  const [openHead, setopenHead] = useState(false)
  const { state = {} } = location

  const {title} = state || '';

  const { gf, gCfpfp, gCfp, gIotCfp,gCompanyEmployee } = useContext(GlobalStateContext)
  const [tbData, settbData] = useState([])


  useEffect(() => {
    if (!title ) {
      navigate("/");
    }else {

      if (!openHead) {
        gIotCfp.sendCmd(state.uuid,gConfig.cmdCode.fingerSetFinish)

      }
      settbData(gCfpfp.deviceFingers)
      gCfpfp.setTemDeviceFingers([])
      return () => {
        gIotCfp.sendCmd(state.uuid,gConfig.cmdCode.fingerSetFinish)
      }
    }

  }, [openHead])
  useEffect(() => {
    gIotCfp.subIotDevices()
    return ()=>{
      gIotCfp.cancelSubscriptions();

    }
  }, []);
  useEffect(() => {
    settbData(gCfpfp.deviceFingers)
  }, [gCfpfp.deviceFingers])
  return (
    <Layout location={location}>
      <Box sx={gf.styles.v}>
        <CfpSearch
          callSearch={(e) => {
            if (e) {
              settbData(
                gUtils.findSearchList(
                  e,
                  gCfpfp.deviceFingers,

                  gCfp.cfpDevices
                )
              )
            } else {
              settbData(gCfpfp.deviceFingers)
            }
          }}
        />
        <Hider show={openHead}>
          <CfpheaderCards
              id={'fingerID'}
            location={location}
            isShowCsv={false}
            setClose={() => {
              setopenHead(false)
            }}
            data={gf.fonts.fontFace}
            goSet={(isChoose) => {
              gIotCfp.sendCmd(state.uuid, isChoose ? gConfig.cmdCode.fingerSet : gConfig.cmdCode.fingerSetFinish)
            }}
            mdatas={gCfpfp.temDeviceFingers}
            addCard={(d) => {

              gCfpfp.updateFp("f", state.uuid, d)

            }}
            tagTitle={"指紋名"}
          />
        </Hider>

        <Cfptb
          callAdd={() => {
            setopenHead(true)
          }}
          isAdd={!openHead}
          data={tbData}
          path={"/cfp/fingerdetails"}
          isBind={false}
          isBack={true}
          text={`${title}${gf.fonts.fontFace.listTitle}`}
          columns={cfpColumns.fingerColumns({gCompanyEmployee:gCompanyEmployee})}
          callDelData={(items) => {
            gIotCfp.sendCmds(state.uuid,gConfig.cmdCode.fingerDelete,items.map(item=>{
              return gUtils.toCardInfo(item.fingerID, '0', item.name)
            }))
          }}
        />
      </Box>
    </Layout>
  )
}
